.logo-container {
    height: auto;
    opacity: 0;
    position: absolute;
    right: 20%;
    top: 10%;
    width: fit-content;
    left: auto;
    margin: auto;
    right: 0px;
    
    img{
      animation: updown 3s linear infinite;   
      width:100vw;
      height: 60vh;
      max-width: 650px;

    }
    animation: fade-in 4s 2s forwards;
  }
  
  @keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}

@media screen and (max-width: 1200px) {

  .logo-container {
    top:100%;
    position: relative;

  }

  .logo-container img {
    top: auto;
    right: auto;
    bottom: auto;
margin-bottom: 5rem;
    height: 40vh;
  }

}