.text-container {
  position: relative;
  width: 100%;
  top: 20em;
  text-align: center;


  h1 {
    color: #fff;
    font-size: 56px;
    line-height: 53px;
    font-family: 'Coolvetica';
    font-weight: 400;
    

    &:before {
      content: '<h1>';
      margin-top: 30em;
      font-family: 'La Belle Aurore', cursive;
      color: #17D3AA;
      font-size: 18px;
      position: relative;
      opacity: 0.6;
    }

    &:after {
      content: '<h1/>';
      font-family: 'La Belle Aurore', cursive;
      color: #17D3AA;
      font-size: 18px;
      position: relative;
      margin-top: 20px;
      margin-left: 20px;
      animation: fadeIn 1s 1.7s backwards;
      opacity: 0.6;
    }

   
  }

}

.projects-container {
  background-image: url('../../assets/images/footer-bg.png');
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 100%;
  
  height: auto;

  padding-bottom: 300px;

  
  

}

.emoji {
  display: inline-block;
  opacity: 0;
  animation: bounceIn;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  min-width: 10px;
  animation-delay: 4s;
  }
