.project-card {
    width: 70%;
    min-height: 200px;
    border-radius: 20px;
    margin-top: 100px;
    padding: 20px;
    display: flex;
    flex-wrap: wrap-reverse;
    opacity: 0;
    background-color: #151515;
    position: sticky;
    box-shadow: 0 0 .2rem #fff,
        0 0 .2rem #fff,
        0 0 2rem #bc13fe,
        0 0 0.8rem #bc13fe,
        0 0 2.8rem #bc13fe,
        inset 0 0 1.3rem #bc13fe;

    .project-text {
        width: 70%;
        font-family: sans-serif;
        position: relative;
        font-size: 18px;
        color: rgb(217, 217, 217);
        text-align: center;
    }

    a {
        position: relative;
        display: inline-block;
        width: 30%;
        height: auto;

        img {
            position: relative;
            display: block;
            margin: 10px;
            width: 90%;
            height: 80%;
        }
    }



}

.rtl {
    direction: rtl;
}

.show {
    animation: fade-in 2s 0s forwards;
}

@media screen and (max-width: 1000px) {
    .rtl {
        direction: ltr;
    }

    .project-card {
        .project-text {
            width: 100%;
        }

        a {
            width: 60%;
            margin: auto;

            img {
                min-height: 100px;
                min-width: 150px;
                margin-bottom: 20px;
            }
        }
    }
}