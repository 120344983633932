
.contact-form {
  width: 100%;
  margin-top: 20px;
  position: relative;
  
  ul {
    position: relative;
    padding: 0;

    li {
      padding: 0;
      list-style: none;
      margin-bottom: 10px;
      opacity: 0;
      overflow: hidden;
      display: block;
      clear: both;
      position: relative;
      animation: fadeInUp 2s 2s;
      animation-fill-mode: forwards;
    }

    li.half {
      width: 49%;
      margin-left: 2%;
      float: left;
      clear: none;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  input[type='text'],
  input[type='email'] {
    position: relative;
    width: 100%;
    border: 0;
    background: #17D3AA;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 0 20px;
    box-sizing: border-box;
  }

  textarea {
    position: relative;
    width: 100%;
    border: 0;
    background: #17D3AA;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 20px;
    box-sizing: border-box;
    min-height: 150px;
  }

  .flat-button {
    position: relative;
    color: #ffd700;
    font-size: 11px;
    letter-spacing: 3px;
    text-decoration: none;
    padding: 8px 10px;
    border: 1px solid #ffd700;
    float: left;
    border-radius: 4px;
    background: 0 0;
    text-transform: uppercase;
    float: right;
    text-align: center;
    margin-right: 10px;

    &:hover {
      background: #ffd700;
      color: #333;
    }
  }
}
.logo-container1{
  z-index: 0;
  width: fit-content;
  height: auto;
  opacity: 0;
  position: absolute;
  top: 10%;
  left: 5%;
  bottom: 0;
  right: auto;
  margin: auto;
    img{
      width:80%;
      height: 80%;
      
      animation: updown 3s linear infinite;    
    }
    animation: fade-in 3s 2s forwards;
}

@keyframes fade-in {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes updown {
  0% {
      transform: translateY(-20px);
  }
  50% {
      transform: translateY(20px);
  }
  100% {
      transform: translateY(-20px);
  }
}