* {
  margin: 0px;
  padding: 0px;
}

html {
  font-size: 62.5%;
}

body {
  font: 300 11px/1.4 'Helvetica Neue', sans-serif;
  color: #444;
  display: block;
}

.dashboard {
  margin-top: 200px;
  margin-left: 200px;
}

@media screen and (max-width: 1200px) {
  body {
    overflow: visible;
  }
}